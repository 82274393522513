import React from "react"
import * as styles from "./NarrowContainer.module.scss"

const NarrowContainer = ({children}) => {
  return (
    <div className={styles.container}>
      {children}
    </div>
  )
}

export default NarrowContainer