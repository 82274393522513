import React from "react"

import useStoryblok from "../lib/storyblok"
import Seo from "../components/global/seo/Seo"
import Layout from "../components/global/layout/Layout"
import DynamicComponent from "../components/DynamicComponent"

import "bootstrap/dist/css/bootstrap.min.css"
import MediaHero from "../components/internal/mediaHero/MediaHero"
import LanguageProvider from "../i18n/LanguageProvider"
import NarrowContainer from "../components/internal/narrowContainer/NarrowContainer"

const MediaPage = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)
  let components = []
  if(story.content.body) {
    components = story.content.body.map(blok => (
      <DynamicComponent blok={blok} key={blok._uid} locale={pageContext.locale} />
    ))
  }

  return (
    <LanguageProvider locale={pageContext.locale}>
      <Layout navigation={pageContext.navigation} location={location} settings={pageContext.globalSettings} locale={pageContext.locale}>
        <style>
          {`body {
            background-color: #f1f1f1;
          }`}
        </style>
        <Seo blok={story && story.content ? story : null} locale={pageContext.locale} location={location}/>
        <MediaHero headline={story.content.headline} image={story.content.image} />
        <NarrowContainer>
          { components }
        </NarrowContainer>
      </Layout>
    </LanguageProvider>
  )
}

export default MediaPage