import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import NarrowContainer from "../narrowContainer/NarrowContainer"

import StoryblokImage from "../storyblokImage/StoryblokImage"

import * as styles from "./MediaHero.module.scss"

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function getFullMonth(month){
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return months[month];
}

const formateDate = (dateString) => {
  return[
    padTo2Digits(dateString.getDate()),
    getFullMonth(dateString.getMonth()),
    padTo2Digits(dateString.getYear() - 100),
  ].join(' ')
}

const HeroWrapper = ({isNarrow, children}) => {
  if(isNarrow) {
    return (
      <NarrowContainer>
        {children}
      </NarrowContainer>
    )
  }
  return (
    <Container>
      {children}
    </Container>
  )
}


const MediaHero = ({ headline, image, isNarrow, preheader, isStory }) => {
  return (
    <div className={styles.container}>
      <HeroWrapper isNarrow={isNarrow || false}>
        <Row>
          {image && image.filename !== ""? (
            <Col xs={12} lg={5}>
              <div className="panel-radius">
              <StoryblokImage sbImageData={image} />
              </div>
            </Col>
          ) : null}
          <Col xs={12} lg={image && image.filename !=="" && image.filename !== null ? 7 : 12} >
            {preheader ? <div className={styles.eventLabel}>
              {(preheader.date && preheader.endDate) && (preheader.date !== preheader.endDate) ? <span>{formateDate(new Date (preheader.date))} - {formateDate(new Date (preheader.endDate))} | </span> : preheader.date ? <span>{formateDate(new Date (preheader.date))} | </span> : null}
              {/* {preheader.date ? <span>{formateDate(new Date (preheader.date))} | </span> : null} */}
              {/* {preheader.endDate ? <span>{formateDate(new Date(preheader.endDate))} | </span> : null} */}
              {preheader.time ? <span>{preheader.time} | </span> : null}
              {preheader.location ? <span>{preheader.location}</span> : null}
            </div> : null}
            <h1 className={(image && image.filename !=="" && image.filename !== null ? styles.header : styles.headerFullWidth) +  " "  +  (isStory ? styles.storyHero: "")}>{headline}</h1> 
          </Col>
        </Row>
      </HeroWrapper>
    </div>
  )
}

export default MediaHero